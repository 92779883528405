import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            {/* <div className="container"> */}
              <div className="row">
                <div className="col-md-3 col-md-offset-1 intro-text">
                  <h1>
                    {this.props.data ? this.props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <p>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>

                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <a href='https://play.google.com/store/apps/details?id=com.emtnapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                      <img alt='Disponível no Google Play' style={{height: 92, margin: 0, marginLeft: -15}} src='https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png'/>
                    </a>{" "}
                    <a href='https://apps.apple.com/br/app/emtn-tull/id1548324445'>
                      <img alt='Baixar na App Store' style={{height: 63}} src="img/app-store-download-pt-br.svg" />
                    </a>
                    {/* <a href='https://br.freepik.com/vetores/medico'>Médico vetor criado por pch.vector - br.freepik.com</a> */}
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
